<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'

/**
 * Contents component
 */
export default {
  page: {
    title: 'New Premium',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader },

  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      title: 'New Premium',
      dataSub: [],
      id: '',
      sub_title: 'sub_title',
      modalShow: false,
      modalAddData: false,
      form: {},
      categoryId: '',
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.onLoad()
  },
  methods: {
    async onLoad() {
      await this.axios
        .get(`/api/subscription/premium/getAllCategory`)
        .then((res) => {
          if (res.data.result) {
            const found = res.data.result.find(
              (element) => element._id === this.id
            )
            this.title = found.name
            this.dataSub = found.ancestors
          }
          // this.dataSub = res.data.result
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },

    async handleClickDeleteContent(id) {
      var confirmDel = confirm('Are you sure you want to delete?')
      this.form.categoryId = id

      console.log(this.form.categoryId)
      if (confirmDel) {
        await this.axios
          .delete(`/api/subscription/premium/removeCategory`, {
            data: {
              categoryId: id,
            },
          })
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },
    async toggleCheckbox() {
      console.log(checked)
    },
    async handleClickEditContent(data) {
      // this.sub_title = `Edit ${data.name}`
      this.form.categoryName = data.name
      this.form.categoryId = data._id
      this.modalShow = true
      // this.$router.push({
      //   path: '/banner/create',
      //   query: { id: data._id },
      // })
    },
    async handleOk(bvModalEvt) {
      await this.axios
        .patch(`/api/subscription/premium/renameCategory`, this.form)
        .then((res) => {
          this.onLoad()
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },
    async handleClickAddContent(data) {
      this.$router.push({
        path: '/newPremium/content',
        query: { id: data._id },
      })
    },
    async createSub() {
      this.modalAddData = true
    },
    async handleOkAdd(bvModalEvt) {
      this.form.parent = this.id
      console.log(this.form, 'form jaaaa')
      await this.axios
        .post(`/api/subscription/premium/createCategory`, this.form)
        .then((res) => {
          this.onLoad()
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">{{ title }}</h4>
      </div>
      <div class="col-xl-6">
        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          @click="createSub()"
        >
          <feather type="file-plus"></feather>Create
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataSub" :key="index">
                    <td scope="row">{{ index + 1 }}</td>

                    <td scope="row">
                      {{ data.name }}
                    </td>

                    <td class="d-flex">
                      <button
                        class="btn btn-info p-0 px-2 py-1 mr-2"
                        @click="handleClickAddContent(data)"
                        >Add Content</button
                      >
                      <button
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        @click="handleClickEditContent(data)"
                        >Edit</button
                      >

                      <b-button
                        @click="handleClickDeleteContent(data._id)"
                        class="btn btn-danger p-0 px-2 py-1 mr-2"
                        v-b-modal.modal-center
                        >Delete</b-button
                      >

                      <!-- <b-form-checkbox
                        switch
                        v-model="feat"
                        @click.native="handleClickfeatContent(data._id, index)"
                        
                      >
                        {{ feat }}
                      </b-form-checkbox> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="lg" title="Edit Data" v-model="modalShow" @ok="handleOk">
      <b-form-group label-cols-lg="0">
        <label>Name</label>
        <b-form-input
          id="title"
          v-model="form.categoryName"
          name="title"
          type="text"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal
      size="lg"
      title="Edit Data"
      v-model="modalAddData"
      @ok="handleOkAdd"
    >
      <b-form-group label-cols-lg="0">
        <label>Name</label>
        <b-form-input
          id="title"
          v-model="form.name"
          name="title"
          type="text"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
.sizeImage {
  width: 50%;
  height: 50%;
}
</style>
